import React, { useState, useEffect } from "react";
import { useEventID } from "wecall-config-lib";
import { Link, Outlet } from "react-router-dom";
import InitialScripts from "../script/initialScripts";
// import logo from "../assets/logo.png";
import badge1 from "../assets/trustseal1.jpg";
import badge2 from "../assets/trustseal2.jpg";
import badge3 from "../assets/trustseal3.jpg";
import badge4 from "../assets/trustseal4.jpg";
import bills from "../assets/bills.webp";
import gas from "../assets/gas.webp";
import food from "../assets/food.webp";
import rent from "../assets/rent.webp";

const FloatingCard = React.lazy(() => import("../floating-card"));

export default function DefaultPage({ setNumber, cityStateResponse }) {
  const { state, city, zip } = cityStateResponse;
  return (
    <>
      <InitialScripts setNumber={setNumber} />
      <div className="App">
        <div className="mainWrapper">
          <header>
            <div className="container">
              <div className="row">
                <div className="logo-wrapper">
                  <a href="#">
                    <img loading="lazy" src={"/assets/logo.png"}></img>
                  </a>
                </div>
              </div>
            </div>
          </header>
          <div className="hero-section">
            <div className="container">
              <div className="row">
                <Outlet />
                {
                  //<div className="logo-strip">
                  //  <div className="logoitem">
                  //    <img  loading="lazy"src={newslogo1}></img>
                  //    <img  loading="lazy"src={newslogo2}></img>
                  //    <img  loading="lazy"src={newslogo3}></img>
                  //    <img  loading="lazy"src={newslogo4}></img>
                  //    <img  loading="lazy"src={newslogo5}></img>
                  //    <img  loading="lazy"src={newslogo6}></img>
                  //  </div>
                  //</div>
                  //
                }
              </div>
            </div>
          </div>
          <div className="badges-section">
            <div className="container">
              <div className="row">
                <div className="badge-headline-wrapper">
                  <h3>
                    Trusted Partners, <br />
                    Secured Promise
                  </h3>
                </div>
                <div className="badge-seal-wrapper">
                  <div className="badge-item">
                    <img src={badge1} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge2} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge3} alt=""></img>
                  </div>
                  <div className="badge-item">
                    <img src={badge4} alt=""></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stats-section">
            <div className="container">
              <div className="row">
                <div className="stats-top">
                  <div className="stats-top-left">
                    <h2>
                      Transforming Lives: The Impact of the Subsidy Program
                    </h2>
                  </div>
                  <div className="stats-top-right">
                    <p>
                      Dive into the remarkable journey of the Subsidy Program, a
                      pivotal initiative reshaping the financial landscape for
                      low-wage Americans. Witness the significant strides made
                      in providing essential support for groceries, rent, gas,
                      and bills. Here's a snapshot of the monumental impact and
                      ongoing progress:
                    </p>
                  </div>
                </div>
                <div className="stats-bottom-counter">
                  <div className="stat-counter-wrapper">
                    <div className="stat-item">
                      <div className="stat-number">$43 Million</div>
                      <div className="stat-label">
                        The ripple effect of financial empowerment is evident,
                        with millions allocated to uplift households across the
                        nation.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">250,000+</div>
                      <div className="stat-label">
                        Many lives have been touched, with more families joining
                        every day to avail the comprehensive benefits.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">1 in 3</div>
                      <div className="stat-label">
                        Reflecting the widespread need, a third of the
                        population qualifies for this life-enhancing program,
                        ensuring no one is left behind.
                      </div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">482 Seats</div>
                      <div className="stat-label">
                        With limited spots remaining, urgency meets opportunity
                        for those aiming to harness the program's full
                        potential.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section1">
            <div className="container">
              <div className="row">
                <div className="section-1-content">
                  <h2>Check for the New Subsidy for Minimum Wage Americans</h2>
                  <p>
                    Claim up to $1,432 SUBSIDY to pay for all your health care
                    needs.
                  </p>
                  <div className="item-benefits">
                    <ul>
                      <li>
                        <div className="benefit">
                          <div className="benefit-content">
                            <h2>Groceries & Food</h2>
                            <p>
                              Make your grocery budget go further with the $6400
                              Subsidy Card. Enjoy the freedom to fill your cart
                              with essentials and more, without the pinch on
                              your wallet.
                            </p>
                          </div>
                          <div className="benefit-image">
                            <img loading="lazy" src={food}></img>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <div className="benefit-content">
                            <h2>Home Rent</h2>
                            <p>
                              Stabilize your living situation with our Subsidy
                              Card. Relieve the pressure of rent and focus on
                              what truly matters in your home and life.
                            </p>
                          </div>
                          <div className="benefit-image">
                            <img loading="lazy" src={rent}></img>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <div className="benefit-content">
                            <h2>Car Gas</h2>
                            <p>
                              Keep your tank full and your travels worry-free.
                              The Subsidy Card takes the edge off commuting
                              costs, fueling your way to work and beyond.
                            </p>
                          </div>
                          <div className="benefit-image">
                            <img loading="lazy" src={gas}></img>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="benefit">
                          <div className="benefit-content">
                            <h2>Bills</h2>
                            <p>
                              Manage your utility and other bills with ease. The
                              Subsidy Card is your ally in keeping the lights on
                              and the water running without financial stress.
                            </p>
                          </div>
                          <div className="benefit-image">
                            <img loading="lazy" src={bills}></img>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="testimonial">
            <div className="container">
              <div className="row">
                <div className="testimonial-headline">
                  <h2>Satisfied Citizens Share Their Stories!</h2>
                  <img loading="lazy" src={"/assets/testimonials.png"} alt="" />
                </div>
                <div className="testimonial-content-holder">
                  <p>
                    We strive to offer you the best service, as evidenced by our
                    Trustpilot star rating.
                  </p>
                </div>
                <div className="testimonial-list">
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I just received mine fast! They worked tirelessly to
                          secure the financial subsidy I needed, and their
                          dedication to customer satisfaction is unmatched.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-1.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Floyd Miles</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          Just in time when i need it the most! I can't thank US
                          Support enough for their prompt assistance. Their
                          team's commitment to me is truly commendable.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-2.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Michael Anderson</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I was in a financial bind, and US Support came to the
                          rescue. Their team's dedication and service to people
                          are top-notch, and I couldn't be happier with their
                          help.
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-4.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>Olivia Martinez</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item">
                    <div className="testimonial-inner">
                      <div className="testimonial-bottom">
                        <p>
                          I'm still in awe of how quickly I received mine. This
                          helps me a lot as a minimum wage earner. Their
                          commitment to help you is evident in every step of the
                          process. Highly recommended!
                        </p>
                      </div>
                      <div className="star-rating">
                        <img loading="lazy" src={"/assets/star.png"} alt="" />
                      </div>
                      <div className="testimonial-top">
                        <div className="testimonial-top-wrapper">
                          <div className="testimonial-left">
                            <img
                              loading="lazy"
                              src={"/assets/user-4.jpeg"}
                            ></img>
                          </div>
                          <div className="testimonial-right">
                            <h3>David Wilson</h3>
                            <p>California, LA</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer">
            <div className="container">
              <div className="row"></div>
            </div>
          </div>
        </div>
      </div>
      <React.Suspense fallback={<></>}>
        <FloatingCard />
      </React.Suspense>
      <LanderSocialEventTrigger />
    </>
  );
}

function LanderSocialEventTrigger() {
  const { getEventId } = useEventID();

  const viewContentKey = "PageView";
  const viewContentKey2 = "PageView2";

  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);

  useEffect(() => {
    if (window.fbcFunc) {
      window.fbcFunc("track", "PageView", { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey, "true");
    }

    if (window.tikTokEvent && params.get("utm_source") === "tiktok") {
      window.tikTokEvent.track("ViewContent", {}, { eventID: getEventId() });
      sessionStorage.setItem(viewContentKey2, "true");
    }
  }, []);
  return <></>;
}
